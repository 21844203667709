import React from "react";
import * as dateFns from "date-fns";

class StepTwo extends React.Component {
  render() {
    let semesters = this.props.Semesters.filter(
      (x) => !this.props.partnerCodeIsCampaign || x.name == "Summer 2024"
    ).map((sem, index) => {
      return (
        <div
          key={index}
          onClick={this.props.step2SemesterRender.bind(this, sem.sequential)}
        >
          <li className="bg-white">
            <label>
              {sem.name +
                " | From: " +
                dateFns.format(sem.startDate, "dd-LLL-yyyy") +
                " - To: " +
                dateFns.format(sem.endDate, "dd-LLL-yyyy")}
              <input type="radio" name="semester" />
            </label>
          </li>
        </div>
      );
    });

    return (
      <div className="multisteps-form__panel" data-animation="slideHorz">
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>
                <span className="step-no bottom-line">Program Selection</span>
                <div className="step-progress float-right">
                  <span>2 of 5 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "40%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <h2>In which semester are you planning to start studying?</h2>
                <label className="label-input">
                  Please choose Start and End date carefully. Any alteration on
                  the Start or End Date will require a $30 ALTERATION FEE
                </label>

                <div className="services-select-option">
                  <ul>{semesters}</ul>
                </div>

                <h2>How long will you be applying for?</h2>

                <div className="services-select-option3 mb-60">
                  <ul>
                    <div onClick={this.props.step2PeriodRender.bind(this, 1)}>
                      <li className="bg-white ">
                        <label>
                          1 Academic Semester (4 months)
                          <input type="radio" name="period" />
                        </label>
                      </li>
                    </div>
                    <div onClick={this.props.step2PeriodRender.bind(this, 2)}>
                      <li className="bg-white">
                        <label>
                          1 Year (3 academic semesters)
                          <input type="radio" name="period" />
                        </label>
                      </li>
                    </div>

                    {this.props.applicationChoiceId == 4 && (
                      <div onClick={this.props.step2PeriodRender.bind(this, 3)}>
                        <li className="bg-white">
                          <label>
                            Other
                            <input type="radio" name="period" />
                          </label>
                        </li>
                      </div>
                    )}
                  </ul>
                </div>

                {this.props.data.PeriodId == 3 && (
                  <div className="row form-inner-area">
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="AnotherDateStart"
                          value={this.props.data.AnotherDateStart}
                          onChange={this.props.handleAnotherDate.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="AnotherDateEnd"
                          value={this.props.data.AnotherDateEnd}
                          onChange={this.props.handleAnotherDate.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <h2>Campus Option</h2>

                <div className="services-select-option2 mb-60">
                  <ul>
                    <div onClick={this.props.step2CampusRender.bind(this, 1)}>
                      <li className="bg-white ">
                        <label>
                          Salt Lake City | UT 84107 | SEVIS Code:
                          DEN214F00899000
                          <input type="radio" name="campus" />
                        </label>
                      </li>
                    </div>
                    <div onClick={this.props.step2CampusRender.bind(this, 2)}>
                      <li className="bg-white">
                        <label>
                          Orem | UT 84097 | SEVIS Code: DEN214F00899001
                          <input type="radio" name="campus" />
                        </label>
                      </li>
                    </div>
                  </ul>
                </div>

                {this.props.applicationChoiceId === 2 && (
                  <div className="upload-documents">
                    <h2>Attach a copy of your current Student (F) Visa</h2>
                    <div className="upload-araa bg-white">
                      <input
                        type="hidden"
                        value=""
                        name="fileContent"
                        id="fileContent"
                      />
                      <input
                        type="hidden"
                        value=""
                        name="filename"
                        id="filename"
                      />
                      <div className="upload-icon float-left">
                        <i className="fas fa-cloud-upload-alt"></i>
                      </div>
                      <div className="upload-text">
                        <span>{this.props.data.FileName}</span>
                      </div>
                      <div className="upload-option text-center">
                        <label htmlFor="Step2Attach">
                          Upload The Documents
                        </label>
                        <input
                          id="Step2Attach"
                          style={{ display: "none" }}
                          type="file"
                          onChange={this.props.onFileChange.bind(
                            this,
                            "Step2",
                            "FileContent",
                            "FileName"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="actions">
            <ul>
              <li>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left"></i> BACK{" "}
                </span>
              </li>
              <li>
                <span
                  className={`js-btn-next 
                  ${this.formValid() ? "" : "button-disabled"}`}
                  title="NEXT"
                >
                  NEXT <i className="fa fa-arrow-right"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  formValid() {
    return (
      this.props.data.SemesterId > -1 &&
      this.props.data.CampusId > -1 &&
      this.props.data.PeriodId > -1 &&
      (this.props.data.PeriodId != 3 ||
        (this.props.data.PeriodId == 3 &&
          this.props.data.AnotherDateStart.length > 0 &&
          this.props.data.AnotherDateEnd.length > 0 &&
          Date.parse(this.props.data.AnotherDateEnd) >
            Date.parse(this.props.data.AnotherDateStart))) &&
      ((this.props.applicationChoiceId === 2 &&
        this.props.data.FileContent !== null &&
        this.props.data.FileName !== "") ||
        this.props.applicationChoiceId !== 2)
    );
  }
}

export default StepTwo;
