/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class StepFive extends React.Component {
  getPersonCard(index) {
    return (
      <React.Fragment key={index}>
        <h2>Dependent {index + 1} Information</h2>

        <div className="row form-inner-area">
          <div className="col-md-8">
            <div className="wizard-form-input position-relative form-group has-float-label">
              <label className="label-input">Full Legal Name</label>
              <input
                type="text"
                className="form-control"
                name="FullName"
                value={
                  this.props.data.Dependents.DependentList[index] &&
                  this.props.data.Dependents.DependentList[index].FullName
                }
                onChange={this.props.handleDependentChange.bind(this, index)}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="wizard-form-input position-relative form-group has-float-label">
              <label className="label-input">Date of Birth (mm/dd/yyyy)</label>
              <input
                type="date"
                className="form-control"
                name="DateofBirth"
                value={
                  this.props.data.Dependents.DependentList[index] &&
                  this.props.data.Dependents.DependentList[index].DateofBirth
                }
                onChange={this.props.handleDependentChange.bind(this, index)}
              />
            </div>
          </div>
        </div>

        <div className="upload-documents">
          <h2 style={{ paddingTop: "0px" }}>
            Attach a copy of the passport for dependent {index + 1} (Biographic
            Page Only)
          </h2>
          <div className="upload-araa bg-white">
            <input type="hidden" value="" name="fileContent" id="fileContent" />
            <input type="hidden" value="" name="filename" id="filename" />
            <div className="upload-icon float-left">
              <i className="fas fa-cloud-upload-alt"></i>
            </div>
            <div className="upload-text">
              <span>{this.props.data.FileName}</span>
              <span>
                {this.props.data.Dependents.DependentList[index] &&
                  this.props.data.Dependents.DependentList[index].FileName}
              </span>
            </div>
            <div className="upload-option text-center">
              <label htmlFor={"AttachDependent" + index}>
                Upload The Documents
              </label>
              <input
                id={"AttachDependent" + index}
                style={{ display: "none" }}
                type="file"
                onChange={this.props.onFileChangeDependent.bind(
                  this,
                  index,
                  "FileContent",
                  "FileName"
                )}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let number = 0;

    if (this.props.data.Dependents.NumberOfDependents.length > 0)
      number = parseInt(this.props.data.Dependents.NumberOfDependents);

    let adultList = [];
    for (let index = 0; index < number; index++) {
      adultList.push(this.getPersonCard(index));
    }

    return (
      <div className="multisteps-form__panel" data-animation="slideHorz">
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>
                <span className="step-no bottom-line">
                  Dependents Information
                </span>
                <div className="step-progress float-right">
                  <span>4 of 5 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "80%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                {this.props.applicationChoiceId === 2 && (
                  <React.Fragment>
                    <h2>Transfer in Information</h2>

                    <div className="row form-inner-area">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            If you are transferring from another ESL school in
                            the US, please enter the name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="TransferSchool"
                            value={this.props.data.Transfer.TransferSchool}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step5",
                              "Transfer"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="upload-documents">
                      <h2 style={{ paddingTop: "0px" }}>
                        Attach a copy of your current I-20 Form
                      </h2>
                      <div className="upload-araa bg-white">
                        <input
                          type="hidden"
                          value=""
                          name="fileContent"
                          id="fileContent"
                        />
                        <input
                          type="hidden"
                          value=""
                          name="filename"
                          id="filename"
                        />
                        <div className="upload-icon float-left">
                          <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                        <div className="upload-text">
                          <span>{this.props.data.I20FileName}</span>
                        </div>
                        <div className="upload-option text-center">
                          <label htmlFor="I20File">Upload The Documents</label>
                          <input
                            id="I20File"
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.props.onFileChange.bind(
                              this,
                              "Step5",
                              "I20FileContent",
                              "I20FileName"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="step-box">
                      <p>
                        In order to process your transfer, it is required to
                        fill the transfer form <strong>correctly</strong>.{" "}
                        <br />
                        <br />
                        <strong>Follow the Steps below:</strong>
                        <br />
                        <br />
                        <strong>1. Download</strong> the transfer form by
                        clicking{" "}
                        <a
                          onClick={this.downloadDocumentFile.bind(this)}
                          style={{
                            color: "#f58220",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          here
                        </a>
                        <br />
                        <strong>2.</strong> Print and Fill the{" "}
                        <strong>SECTION A</strong> of the transfer form.
                        <br />
                        <strong>3.</strong> Don't forget to{" "}
                        <strong>sign</strong> and <strong>date</strong> your
                        form.
                        <br />
                        <strong>4. Attach</strong> the form to your application.
                      </p>
                    </div>

                    <div className="upload-documents">
                      <h2 style={{ paddingTop: "0px" }}>
                        Attach a copy of the Transfer Form - See instructions
                        above
                      </h2>
                      <div className="upload-araa bg-white">
                        <input
                          type="hidden"
                          value=""
                          name="fileContent"
                          id="fileContent"
                        />
                        <input
                          type="hidden"
                          value=""
                          name="filename"
                          id="filename"
                        />
                        <div className="upload-icon float-left">
                          <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                        <div className="upload-text">
                          <span>{this.props.data.TransferFileName}</span>
                        </div>
                        <div className="upload-option text-center">
                          <label htmlFor="TransferFile">
                            Upload The Documents
                          </label>
                          <input
                            id="TransferFile"
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.props.onFileChange.bind(
                              this,
                              "Step5",
                              "TransferFileContent",
                              "TransferFileName"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <h2>Dependents</h2>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">
                        Do you have dependents (F2) coming with you?
                      </label>
                      <div className="language-select">
                        <select
                          name="languages"
                          onChange={this.props.handleHasDependentSelectChange.bind(
                            this
                          )}
                          value={this.props.data.Dependents.HasDependents}
                        >
                          <option value="">Select</option>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {this.props.data.Dependents.HasDependents === "Y" && (
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">
                          How many dependents do you have?
                        </label>
                        <div className="language-select">
                          <select
                            name="languages"
                            onChange={this.props.handleSelectChange.bind(
                              this,
                              "Step5",
                              "Dependents",
                              "NumberOfDependents"
                            )}
                            value={
                              this.props.data.Dependents.NumberOfDependents
                            }
                          >
                            <option value="">Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {adultList}
              </div>
            </div>
          </div>
          <div className="actions">
            <ul>
              <li>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left"></i> BACK{" "}
                </span>
              </li>
              <li>
                <span
                  className={`js-btn-next ${
                    this.formValid() ? "" : "button-disabled"
                  }`}
                  title="NEXT"
                >
                  NEXT <i className="fa fa-arrow-right"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  async downloadDocumentFile() {
    const requestOptions = {
      crossDomain: true,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const isDevelopment = false;
    const url = isDevelopment
      ? "http://localhost:58794"
      : "https://api.lumos.edu";

    return fetch(url + "/apply/getTransferForm", requestOptions)
      .then((response) => response.blob())
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", "New Transfer Form.pdf");
        document.body.appendChild(link);

        link.click();
      });
  }

  validDependent(dependent) {
    return (
      dependent &&
      dependent !== undefined &&
      dependent.FullName &&
      dependent.FullName.length > 0 &&
      dependent.DateofBirth &&
      dependent.DateofBirth.length > 0 &&
      dependent.FileContent &&
      dependent.FileContent !== null &&
      dependent.FileName &&
      dependent.FileName !== ""
    );
  }

  invalidDependents() {
    let isInvalid = false;

    for (
      let index = 0;
      index < parseInt(this.props.data.Dependents.NumberOfDependents);
      index++
    ) {
      if (!this.validDependent(this.props.data.Dependents.DependentList[index]))
        isInvalid = true;
    }

    return isInvalid;
  }

  formValid() {
    return (
      (this.props.applicationChoiceId !== 2 ||
        (this.props.applicationChoiceId === 2 &&
          this.props.data.Transfer.TransferSchool.length > 0 &&
          this.props.data.I20FileContent !== null &&
          this.props.data.I20FileName !== "" &&
          this.props.data.TransferFileContent !== null &&
          this.props.data.TransferFileName !== "")) &&
      this.props.data.Dependents.HasDependents.length > 0 &&
      (this.props.data.Dependents.HasDependents === "N" ||
        (this.props.data.Dependents.HasDependents === "Y" &&
          this.props.data.Dependents.NumberOfDependents.length > 0 &&
          !this.invalidDependents()))
    );
  }
}

export default StepFive;
