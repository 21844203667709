import react from "react";
import React from "react";

class StepThree extends React.Component {
  render() {
    let invalidEmail =
      this.props.data.PersonalInformation.InvalidEmail === true;

    return (
      <div className="multisteps-form__panel" data-animation="slideHorz">
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>
                <span className="step-no bottom-line">
                  Personal Information
                </span>
                <div className="step-progress float-right">
                  <span>3 of 5 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "60%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <h2>Personal Information</h2>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">
                        Full Legal Name (According to Passport)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="FullName"
                        value={this.props.data.PersonalInformation.FullName}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "PersonalInformation"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Email</label>

                      <input
                        type="email"
                        className={`form-control ${
                          invalidEmail === true ? " input-error " : ""
                        }`}
                        name="Email"
                        value={this.props.data.PersonalInformation.Email}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "PersonalInformation"
                        )}
                        onBlur={this.props.emailOnBlur.bind(this)}
                      />
                      {invalidEmail === true && (
                        <span className="text-danger">Invalid e-mail</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        name="DateOfBirth"
                        value={this.props.data.PersonalInformation.DateOfBirth}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "PersonalInformation"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Country of Birth</label>
                      <div className="language-select">
                        <select
                          onChange={this.props.handleSelectChange.bind(
                            this,
                            "Step3",
                            "PersonalInformation",
                            "CountryOfBirth"
                          )}
                          value={
                            this.props.data.PersonalInformation.CountryOfBirth
                          }
                        >
                          {this.props.Countries.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">
                        Country of Citizenship
                      </label>
                      <div className="language-select">
                        <select
                          onChange={this.props.handleSelectChange.bind(
                            this,
                            "Step3",
                            "PersonalInformation",
                            "CountryOfCitizenship"
                          )}
                          value={
                            this.props.data.PersonalInformation
                              .CountryOfCitizenship
                          }
                        >
                          {this.props.Countries.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Gender</label>
                      <div className="language-select">
                        <select
                          onChange={this.props.handleSelectChange.bind(
                            this,
                            "Step3",
                            "PersonalInformation",
                            "Gender"
                          )}
                          value={this.props.data.PersonalInformation.Gender}
                        >
                          <option value="">Select</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="PhoneNumber"
                        value={this.props.data.PersonalInformation.PhoneNumber}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "PersonalInformation"
                        )}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="row form-inner-area">
                  <div className="col-md-4">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Gender</label>
                      <div className="language-select">
                        <select
                          onChange={this.props.handleSelectChange.bind(
                            this,
                            "Step3",
                            "PersonalInformation",
                            "Gender"
                          )}
                          value={this.props.data.PersonalInformation.Gender}
                        >
                          <option value="">Select</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="PhoneNumber"
                        value={this.props.data.PersonalInformation.PhoneNumber}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "PersonalInformation"
                        )}
                      />
                    </div>
                  </div>
                </div> */}

                {this.props.applicationChoiceId === 3 && (
                  <div className="row form-inner-area">
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">
                          Date of Entry in the USA
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="DateEntryUsa"
                          value={
                            this.props.data.PersonalInformation.DateEntryUsa
                          }
                          onChange={this.props.handleUserInput.bind(
                            this,
                            "Step3",
                            "PersonalInformation"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="upload-documents">
                  {(this.props.applicationChoiceId === 4 && (
                    <h2>
                      Attach a copy of your Passport / ID (Biographic Page Only)
                    </h2>
                  )) || (
                    <h2>
                      Attach a copy of your Passport (Biographic Page Only)
                    </h2>
                  )}

                  <div className="upload-araa bg-white">
                    <input
                      type="hidden"
                      value=""
                      name="fileContent"
                      id="fileContent"
                    />
                    <input
                      type="hidden"
                      value=""
                      name="filename"
                      id="filename"
                    />
                    <div className="upload-icon float-left">
                      <i className="fas fa-cloud-upload-alt"></i>
                    </div>
                    <div className="upload-text">
                      <span>{this.props.data.FileName}</span>
                    </div>
                    <div className="upload-option text-center">
                      <label htmlFor="Step3Attach">Upload The Documents</label>
                      <input
                        id="Step3Attach"
                        style={{ display: "none" }}
                        type="file"
                        onChange={this.props.onFileChange.bind(
                          this,
                          "Step3",
                          "FileContent",
                          "FileName"
                        )}
                      />
                    </div>
                  </div>
                </div>

                {this.props.applicationChoiceId !== 4 && (
                  <react.Fragment>
                    <h2>Address (Outside the US)</h2>

                    <div className="row form-inner-area">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Street Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Street"
                            value={this.props.data.AddressOutside.Street}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step3",
                              "AddressOutside"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Address Line 2</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Street2"
                            value={this.props.data.AddressOutside.Street2}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step3",
                              "AddressOutside"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">City</label>
                          <input
                            type="text"
                            className="form-control"
                            name="City"
                            value={this.props.data.AddressOutside.City}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step3",
                              "AddressOutside"
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            State/Region/Province
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="State"
                            value={this.props.data.AddressOutside.State}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step3",
                              "AddressOutside"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row form-inner-area">
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">
                            Postal / Zip Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Zip"
                            value={this.props.data.AddressOutside.Zip}
                            onChange={this.props.handleUserInput.bind(
                              this,
                              "Step3",
                              "AddressOutside"
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <label className="label-input">Country</label>
                          <div className="language-select">
                            <select
                              onChange={this.props.handleSelectChange.bind(
                                this,
                                "Step3",
                                "AddressOutside",
                                "Country"
                              )}
                              value={this.props.data.AddressOutside.Country}
                            >
                              {this.props.Countries.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.props.applicationChoiceId !== 2 &&
                      this.props.applicationChoiceId !== 3 && (
                        <React.Fragment>
                          <h2 style={{ color: "#f58220" }}>* IMPORTANT</h2>

                          <p>
                            If you already have an address where you will live
                            while studying in the US, please fill the section
                            below with your address in the US.
                          </p>
                          <br />

                          <p>
                            If you don't have an address where you will live,
                            please fill the form with the{" "}
                            <strong>school address</strong>.
                          </p>

                          <br />

                          <p>
                            220 E 3900 S<br /> Salt Lake City - UTAH <br />
                            84107 - United States
                          </p>
                        </React.Fragment>
                      )}
                  </react.Fragment>
                )}

                <h2>Address (Inside the US)</h2>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Street Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Street"
                        value={this.props.data.AddressInside.Street}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Address Line 2</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Street2"
                        value={this.props.data.AddressInside.Street2}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="City"
                        maxLength={"50"}
                        value={this.props.data.AddressInside.City}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">
                        State/Region/Province
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="State"
                        maxLength={"15"}
                        value={this.props.data.AddressInside.State}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Postal / Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Zip"
                        maxLength={"10"}
                        value={this.props.data.AddressInside.Zip}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "AddressInside"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Country</label>
                      <div className="language-select">
                        <select
                          onChange={this.props.handleSelectChange.bind(
                            this,
                            "Step3",
                            "AddressInside",
                            "Country"
                          )}
                          value={this.props.data.AddressInside.Country}
                        >
                          {this.props.Countries.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <h2>Emergency Contact</h2>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="FullName"
                        value={this.props.data.EmergencyContact.FullName}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Relationship</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Relationship"
                        value={this.props.data.EmergencyContact.Relationship}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="PhoneNumber"
                        value={this.props.data.EmergencyContact.PhoneNumber}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Street Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Street"
                        value={this.props.data.EmergencyContact.Street}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-12">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Address Line 2</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Street2"
                        value={this.props.data.EmergencyContact.Street2}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="City"
                        value={this.props.data.EmergencyContact.City}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">
                        State/Region/Province
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="State"
                        value={this.props.data.EmergencyContact.State}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row form-inner-area">
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Postal / Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Zip"
                        value={this.props.data.EmergencyContact.Zip}
                        onChange={this.props.handleUserInput.bind(
                          this,
                          "Step3",
                          "EmergencyContact"
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="wizard-form-input position-relative form-group has-float-label">
                      <label className="label-input">Country</label>
                      <div className="language-select">
                        <select
                          onChange={this.props.handleSelectChange.bind(
                            this,
                            "Step3",
                            "EmergencyContact",
                            "Country"
                          )}
                          value={this.props.data.EmergencyContact.Country}
                        >
                          {this.props.Countries.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <ul>
              <li>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left"></i> BACK{" "}
                </span>
              </li>
              <li>
                <span
                  className={`js-btn-next ${
                    this.formValid() ? "" : "button-disabled"
                  }`}
                  title="NEXT"
                >
                  NEXT <i className="fa fa-arrow-right"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  formValid() {
    return (
      this.props.data.PersonalInformation.FullName.length > 0 &&
      this.props.data.PersonalInformation.Email.length > 0 &&
      this.props.data.PersonalInformation.InvalidEmail === false &&
      this.props.data.PersonalInformation.DateOfBirth.length > 0 &&
      this.props.data.PersonalInformation.CountryOfBirth.length > 0 &&
      this.props.data.PersonalInformation.CountryOfCitizenship.length > 0 &&
      this.props.data.PersonalInformation.Gender.length > 0 &&
      this.props.data.PersonalInformation.PhoneNumber.length > 0 &&
      ((this.props.applicationChoiceId === 3 &&
        this.props.data.PersonalInformation.DateEntryUsa.length > 0) ||
        this.props.applicationChoiceId !== 3) &&
      this.props.data.FileContent !== null &&
      this.props.data.FileName !== "" &&
      ((this.props.applicationChoiceId !== 4 &&
        this.props.data.AddressOutside.Street.length > 0 &&
        this.props.data.AddressOutside.City.length > 0 &&
        this.props.data.AddressOutside.State.length > 0 &&
        this.props.data.AddressOutside.Zip.length > 0 &&
        this.props.data.AddressOutside.Country.length > 0) ||
        this.props.applicationChoiceId === 4) &&
      this.props.data.AddressInside.Street.length > 0 &&
      this.props.data.AddressInside.City.length > 0 &&
      this.props.data.AddressInside.State.length > 0 &&
      this.props.data.AddressInside.Zip.length > 0 &&
      this.props.data.AddressInside.Country.length > 0 &&
      this.props.data.EmergencyContact.FullName.length > 0 &&
      this.props.data.EmergencyContact.Relationship.length > 0 &&
      this.props.data.EmergencyContact.PhoneNumber.length > 0 &&
      this.props.data.EmergencyContact.Street.length > 0 &&
      this.props.data.EmergencyContact.City.length > 0 &&
      this.props.data.EmergencyContact.State.length > 0 &&
      this.props.data.EmergencyContact.Zip.length > 0 &&
      this.props.data.EmergencyContact.Country.length > 0
    );
  }
}

export default StepThree;
