import React from "react";
import SignaturePad from "react-signature-canvas";
import Modal from "react-modal";
import react from "react";
import Cards from "react-credit-cards-2";

class StepSix extends React.Component {
  sigPad = {};

  clear = (e) => {
    e.preventDefault();
    this.props.clear().then(() => this.sigPad.clear());
  };

  trim = () => {
    this.props.trim(this.sigPad.getCanvas().toDataURL("image/png"));
  };

  render() {
    let total = (
      this.props.ApplicationObj.Step1.Amount -
      this.props.ApplicationObj.Step1.Amount *
        (this.props.ApplicationObj.Step1.PartnerDiscountPercentage / 100)
    ).toFixed(2);

    let review =
      "Application Choice : " +
      this.props.getApplicationChoiceName() +
      "\n\n" +
      (this.props.ApplicationObj.Step1.FinalPartnerCode.length > 0
        ? "Partner Code : " +
          this.props.ApplicationObj.Step1.FinalPartnerCode +
          "\n" +
          "Partner Discount : " +
          this.props.ApplicationObj.Step1.PartnerDiscountPercentage +
          "%" +
          "\n" +
          "Amount : U$" +
          total +
          " (U$100.00 - " +
          this.props.ApplicationObj.Step1.PartnerDiscountPercentage +
          "%)"
        : "Amount : U$" + total) +
      "\n\n" +
      (this.props.ApplicationObj.Step1.FinalReferralCode.length > 0
        ? "Referral Code : " +
          this.props.ApplicationObj.Step1.FinalReferralCode +
          "\n\n"
        : "") +
      "Semester : " +
      this.props.getApplicationSemester() +
      "\n" +
      "I-20 Lenght : " +
      this.props.getApplicationPeriod() +
      "\n" +
      "Campus : " +
      this.props.getApplicationCampus() +
      "\n" +
      (this.props.ApplicationObj.Step1.ApplicationChoiceId === 2
        ? "Current Visa Document : " +
          this.props.ApplicationObj.Step2.FileName +
          "\n"
        : "") +
      "\n" +
      "Personal Information" +
      "\n\n" +
      "Full Name : " +
      this.props.ApplicationObj.Step3.PersonalInformation.FullName +
      "\n" +
      "Email : " +
      this.props.ApplicationObj.Step3.PersonalInformation.Email +
      "\n" +
      "Date Of Birth : " +
      this.props.ApplicationObj.Step3.PersonalInformation.DateOfBirth +
      "\n" +
      "Country Of Birth : " +
      this.props.ApplicationObj.Step3.PersonalInformation.CountryOfBirth +
      "\n" +
      "Country Citizenship : " +
      this.props.ApplicationObj.Step3.PersonalInformation.CountryOfCitizenship +
      "\n" +
      "Gender : " +
      this.props.ApplicationObj.Step3.PersonalInformation.Gender +
      "\n" +
      "Phone Number : " +
      this.props.ApplicationObj.Step3.PersonalInformation.PhoneNumber +
      "\n" +
      "Passport Document : " +
      this.props.ApplicationObj.Step3.FileName +
      "\n" +
      (this.props.ApplicationObj.Step1.ApplicationChoiceId === 3
        ? "Date Entry Usa : " +
          this.props.ApplicationObj.Step3.PersonalInformation.DateEntryUsa +
          "\n"
        : "") +
      "\n" +
      (this.props.ApplicationObj.Step1.ApplicationChoiceId !== 4
        ? "Address Outside" +
          "\n\n" +
          "Street : " +
          this.props.ApplicationObj.Step3.AddressOutside.Street +
          "\n" +
          "Street2 : " +
          this.props.ApplicationObj.Step3.AddressOutside.Street2 +
          "\n" +
          "City : " +
          this.props.ApplicationObj.Step3.AddressOutside.City +
          "\n" +
          "State : " +
          this.props.ApplicationObj.Step3.AddressOutside.State +
          "\n" +
          "Zip : " +
          this.props.ApplicationObj.Step3.AddressOutside.Zip +
          "\n" +
          "Country : " +
          this.props.ApplicationObj.Step3.AddressOutside.Country +
          "\n\n"
        : "") +
      "Address Inside" +
      "\n\n" +
      "Street : " +
      this.props.ApplicationObj.Step3.AddressInside.Street +
      "\n" +
      "Street2 : " +
      this.props.ApplicationObj.Step3.AddressInside.Street2 +
      "\n" +
      "City : " +
      this.props.ApplicationObj.Step3.AddressInside.City +
      "\n" +
      "State : " +
      this.props.ApplicationObj.Step3.AddressInside.State +
      "\n" +
      "Zip : " +
      this.props.ApplicationObj.Step3.AddressInside.Zip +
      "\n" +
      "Country : " +
      this.props.ApplicationObj.Step3.AddressInside.Country +
      "\n\n" +
      "Emergency Contact" +
      "\n\n" +
      "Full Name : " +
      this.props.ApplicationObj.Step3.EmergencyContact.FullName +
      "\n" +
      "Relationship : " +
      this.props.ApplicationObj.Step3.EmergencyContact.Relationship +
      "\n" +
      "Phone Number : " +
      this.props.ApplicationObj.Step3.EmergencyContact.PhoneNumber +
      "\n" +
      "Street : " +
      this.props.ApplicationObj.Step3.EmergencyContact.Street +
      "\n" +
      "Street2 : " +
      this.props.ApplicationObj.Step3.EmergencyContact.Street2 +
      "\n" +
      "City : " +
      this.props.ApplicationObj.Step3.EmergencyContact.City +
      "\n" +
      "State : " +
      this.props.ApplicationObj.Step3.EmergencyContact.State +
      "\n" +
      "Zip : " +
      this.props.ApplicationObj.Step3.EmergencyContact.Zip +
      "\n" +
      "Country : " +
      this.props.ApplicationObj.Step3.EmergencyContact.Country +
      "\n\n" +
      (this.props.ApplicationObj.Step1.ApplicationChoiceId === 2
        ? "Transfer School : " +
          this.props.ApplicationObj.Step5.Transfer.TransferSchool +
          "\n" +
          "Transfer Form Document : " +
          this.props.ApplicationObj.Step5.TransferFileName +
          "\n\n"
        : "") +
      (this.props.ApplicationObj.Step1.ApplicationChoiceId === 2
        ? "Current I20 Document : " +
          this.props.ApplicationObj.Step5.I20FileName +
          "\n"
        : "") +
      (this.props.ApplicationObj.Step1.ApplicationChoiceId !== 4
        ? "Bank Statement Document : " +
          this.props.ApplicationObj.Step6.FileName +
          "\n"
        : "") +
      "Affidavit Document : " +
      this.props.ApplicationObj.Step6.AffidavitFileName +
      "\n\n" +
      "Total Dependents : " +
      (this.props.ApplicationObj.Step5.Dependents.HasDependents !== "Y"
        ? "0"
        : this.props.ApplicationObj.Step5.Dependents.NumberOfDependents) +
      "\n\n";

    if (this.props.ApplicationObj.Step5.Dependents.HasDependents === "Y") {
      review += "Dependents: \n\n";

      this.props.ApplicationObj.Step5.Dependents.DependentList.map(
        (dependent, index) =>
          (review +=
            "Full Name : " +
            dependent.FullName +
            "\n" +
            "Date Of Birth : " +
            dependent.DateofBirth +
            "\n" +
            "Passport Document : " +
            dependent.FileName +
            "\n\n")
      );
    }

    return (
      <div className="multisteps-form__panel" data-animation="slideHorz">
        <div className="wizard-forms">
          <div className="inner pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                <div className="row" style={{ marginLeft: "0" }}>
                  <span
                    className="step-box-icon "
                    style={{ marginBottom: "4rem" }}
                  >
                    <img
                      src={require("../../assets/img/logo_lumos.png").default}
                      alt=""
                    />
                  </span>
                </div>
                <span className="step-no bottom-line">
                  Financial Information
                </span>
                <div className="step-progress float-right">
                  <span>5 of 5 completed</span>
                  <div className="step-progress-bar">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                {this.props.ApplicationObj.Step1.ApplicationChoiceId !== 4 && (
                  <react.Fragment>
                    <h2>Financial Information </h2>

                    <p>
                      When applying for a student visa, it is necessary to
                      attach a proof of funds that certifies that the student
                      has funds to pay for his/hers tuition fees and living
                      expenses.
                      <br />
                      <br /> Lumos requires that an F1 student must have $8,740,
                      and $2,500 for spouse F2 dependent, and $2,000 for
                      children under 21 years of age.
                      <br />
                      <br /> Ex: Family of 4 people
                      <br />
                      <br /> F1 = 1x $8,740
                      <br /> F2 Spouse = 1x $2,500
                      <br /> F2 Children = 2x $2,000
                      <br />
                      <br /> Total = $15,240 <br />
                      <br />
                      The funds must be in a checking account or savings account
                      in US Dollars or the equivalent in local currency. <br />
                      <br />
                      The Banks Statement must be an official document
                      containing the logo of the bank, account number and name
                      of the account holder visible.
                      <br />
                      <br /> If you have a sponsor who will show the bank
                      statement in your place, they should demonstrate at least
                      double the amount. Documents such as income tax or
                      statements of legal entity cannot be accepted as proof of
                      funds.
                    </p>

                    <div className="upload-documents">
                      <h2>Attach a copy of the Bank statement</h2>
                      <div className="upload-araa bg-white">
                        <input
                          type="hidden"
                          value=""
                          name="fileContent"
                          id="fileContent"
                        />
                        <input
                          type="hidden"
                          value=""
                          name="filename"
                          id="filename"
                        />
                        <div className="upload-icon float-left">
                          <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                        <div className="upload-text">
                          <span>{this.props.data.FileName}</span>
                        </div>
                        <div className="upload-option text-center">
                          <label htmlFor="Step6BankStatement">
                            Upload The Documents
                          </label>
                          <input
                            id="Step6BankStatement"
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.props.onFileChange.bind(
                              this,
                              "Step6",
                              "FileContent",
                              "FileName"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="upload-documents">
                      <h2>
                        Attach a copy of the Affidavit of Financial Support
                      </h2>
                      <div className="upload-araa bg-white">
                        <input
                          type="hidden"
                          value=""
                          name="fileContent"
                          id="fileContent"
                        />
                        <input
                          type="hidden"
                          value=""
                          name="filename"
                          id="filename"
                        />
                        <div className="upload-icon float-left">
                          <i className="fas fa-cloud-upload-alt"></i>
                        </div>
                        <div className="upload-text">
                          <span>{this.props.data.AffidavitFileName}</span>
                        </div>
                        <div className="upload-option text-center">
                          <label htmlFor="AffidavitFile">
                            Upload The Documents
                          </label>
                          <input
                            id="AffidavitFile"
                            style={{ display: "none" }}
                            type="file"
                            onChange={this.props.onFileChange.bind(
                              this,
                              "Step6",
                              "AffidavitFileContent",
                              "AffidavitFileName"
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {this.props.ApplicationObj.Step1.ApplicationChoiceId !==
                      2 && (
                      <react.Fragment>
                        <h2>Policies and Procedures</h2>

                        <div className="budget-area">
                          {(this.props.ApplicationObj.Step1
                            .ApplicationChoiceId === 1 && (
                            <react.Fragment>
                              <p>
                                I am aware that I'm the solely responsible for
                                paying the SEVIS I-901 ($350).
                              </p>

                              <div
                                className="opti-list"
                                onClick={this.props.handleCheckbox.bind(
                                  this,
                                  1
                                )}
                              >
                                <ul className="d-md-flex">
                                  <li className="bg-white ">
                                    <input type="checkbox" name="Agree1" />I
                                    agree
                                  </li>
                                </ul>
                              </div>
                            </react.Fragment>
                          )) ||
                            (this.props.ApplicationObj.Step1
                              .ApplicationChoiceId === 3 && (
                              <react.Fragment>
                                <p>
                                  I am aware that I’m the solely responsible for
                                  paying the SEVIS I-901 fee ($350).
                                </p>

                                <div
                                  className="opti-list"
                                  onClick={this.props.handleCheckbox.bind(
                                    this,
                                    1
                                  )}
                                >
                                  <ul className="d-md-flex">
                                    <li className="bg-white ">
                                      <input type="checkbox" name="Agree1" />I
                                      agree
                                    </li>
                                  </ul>
                                </div>
                              </react.Fragment>
                            ))}
                        </div>

                        <div className="budget-area">
                          {(this.props.ApplicationObj.Step1
                            .ApplicationChoiceId === 1 && (
                            <react.Fragment>
                              <p>
                                I am aware that I'm the solely responsible for
                                scheduling the interview at the U.S Embassy or
                                Consulate of my choice and for paying any fees
                                related to the visa process.
                              </p>

                              <div
                                className="opti-list"
                                onClick={this.props.handleCheckbox.bind(
                                  this,
                                  2
                                )}
                              >
                                <ul className="d-md-flex">
                                  <li className="bg-white ">
                                    <input
                                      type="checkbox"
                                      name="code_opti1"
                                      value="Semantic coding"
                                    />
                                    I agree
                                  </li>
                                </ul>
                              </div>
                            </react.Fragment>
                          )) ||
                            (this.props.ApplicationObj.Step1
                              .ApplicationChoiceId === 3 && (
                              <react.Fragment>
                                <p>
                                  I am aware that I’m the solely responsible for
                                  finding my own attorney to help me with the
                                  Change of Status process and that the school
                                  is not responsible for the process with the
                                  exception of providing the I-20 document for
                                  the Change of Status process within 7 days of
                                  the submission of this application.
                                </p>

                                <div
                                  className="opti-list"
                                  onClick={this.props.handleCheckbox.bind(
                                    this,
                                    2
                                  )}
                                >
                                  <ul className="d-md-flex">
                                    <li className="bg-white ">
                                      <input
                                        type="checkbox"
                                        name="code_opti1"
                                        value="Semantic coding"
                                      />
                                      I agree
                                    </li>
                                  </ul>
                                </div>
                              </react.Fragment>
                            ))}
                        </div>

                        <div className="budget-area">
                          {(this.props.ApplicationObj.Step1
                            .ApplicationChoiceId === 1 && (
                            <react.Fragment>
                              <p>
                                I am aware that I am responsible for notifying
                                the school about the status of my visa
                                interview, as well as to notify if my visa was
                                approved or not.
                              </p>

                              <div
                                className="opti-list"
                                onClick={this.props.handleCheckbox.bind(
                                  this,
                                  3
                                )}
                              >
                                <ul className="d-md-flex">
                                  <li className="bg-white ">
                                    <input
                                      type="checkbox"
                                      name="code_opti1"
                                      value="Semantic coding"
                                    />
                                    I agree
                                  </li>
                                </ul>
                              </div>
                            </react.Fragment>
                          )) ||
                            (this.props.ApplicationObj.Step1
                              .ApplicationChoiceId === 3 && (
                              <react.Fragment>
                                <p>
                                  I am aware that I am responsible for notifying
                                  the school about my Change of Status
                                  approval/denial. If approved, I’m the
                                  responsible to notify the school and present
                                  myself at the school to complete the
                                  registration and enrollment.
                                </p>

                                <div
                                  className="opti-list"
                                  onClick={this.props.handleCheckbox.bind(
                                    this,
                                    3
                                  )}
                                >
                                  <ul className="d-md-flex">
                                    <li className="bg-white ">
                                      <input
                                        type="checkbox"
                                        name="code_opti1"
                                        value="Semantic coding"
                                      />
                                      I agree
                                    </li>
                                  </ul>
                                </div>
                              </react.Fragment>
                            ))}
                        </div>

                        {this.props.ApplicationObj.Step1.ApplicationChoiceId ===
                          1 && (
                          <div className="budget-area">
                            <p>
                              I am aware that I'm responsible for paying $925.60
                              as part of the tuition and fees, student ID, and
                              placement test if my visa is approved before
                              arrive in the US. Lumos has the right to cancel my
                              I-20 if I don't pay which will make me ineligible
                              to enter the US with a student visa. This amount
                              is not refundable. In case of credit/debit card
                              payment, a 4% credit card fee must be included.
                            </p>
                            <div
                              className="opti-list"
                              onClick={this.props.handleCheckbox.bind(this, 4)}
                            >
                              <ul className="d-md-flex">
                                <li className="bg-white ">
                                  <input
                                    type="checkbox"
                                    name="code_opti1"
                                    value="Semantic coding"
                                  />
                                  I agree
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}

                        <div className="budget-area">
                          {(this.props.ApplicationObj.Step1
                            .ApplicationChoiceId === 1 && (
                            <react.Fragment>
                              <p>
                                I understand that if I made a mistake on the
                                application such as incorrect start/end dates,
                                or I would like to change my sponsor’s bank
                                statement information, there will be a $30
                                ALTERATION fee. This fee must be paid before
                                receiving an updated I-20.
                              </p>
                              <div
                                className="opti-list"
                                onClick={this.props.handleCheckbox.bind(
                                  this,
                                  6
                                )}
                              >
                                <ul className="d-md-flex">
                                  <li className="bg-white ">
                                    <input
                                      type="checkbox"
                                      name="code_opti1"
                                      value="Semantic coding"
                                    />
                                    I agree
                                  </li>
                                </ul>
                              </div>
                            </react.Fragment>
                          )) ||
                            (this.props.ApplicationObj.Step1
                              .ApplicationChoiceId === 3 && (
                              <react.Fragment>
                                <p>
                                  I understand that if I made a mistake on the
                                  application such as incorrect start/end dates,
                                  or I would like to change my sponsor’s bank
                                  statement information, there will be a $30
                                  ALTERATION fee. This fee must be paid before
                                  receiving an updated I-20.
                                </p>
                                <div
                                  className="opti-list"
                                  onClick={this.props.handleCheckbox.bind(
                                    this,
                                    6
                                  )}
                                >
                                  <ul className="d-md-flex">
                                    <li className="bg-white ">
                                      <input
                                        type="checkbox"
                                        name="code_opti1"
                                        value="Semantic coding"
                                      />
                                      I agree
                                    </li>
                                  </ul>
                                </div>
                              </react.Fragment>
                            ))}
                        </div>
                      </react.Fragment>
                    )}
                  </react.Fragment>
                )}

                <h2>Terms and Conditions</h2>

                <div className="budget-area">
                  <p style={{ textAlign: "justify" }}>
                    <strong>Admissions Criteria:</strong> a) Students are
                    generally beyond high school age; b) have the finances in
                    place to pay their tuition; c) willing to comply with the
                    school’s policies; d) students goals align with the program
                    objectives; e) cannot have studied 36 months in an IEP
                    program as an F1 student and f) not transferring from a
                    college or university other than an IEP program. If you
                    request a Student Visa, you must study 18 hours per week and
                    if you decide to come with a Tourist Visa you can study up
                    to 16 hours per week.
                    <br />
                    <br />
                    <strong>Refund Process:</strong> Tuition is the only service
                    that could be refunded; fees for the Application
                    (US$100.00), Placement Test (US$30.00), Student ID
                    (US$20.00), and Books (prices vary according to levels) are
                    NON-REFUNDABLE. <br /> <br />
                    Students requesting a refund should send a written request
                    to Student Financial Services via email (finance@lumos.edu).
                    If the refund request meets the school’s refund policy, the
                    refund will be made to the student within 30 days in the
                    form of a check. The student will receive an email from the
                    bookkeeper when the check is ready to be picked up.
                    Student's failure to notify the appropriate parties in
                    writing of withdrawal may delay refund of tuition. <br />
                    <br />
                    The institution shall be entitled to retain no more than
                    $200 in tuition or fees as registration charges or an
                    alternative amount that the institution can demonstrate to
                    have been expended in preparation for that student’s
                    enrollment. <br />
                    <br />
                    The school will consider the Date of Enrollment (DE)
                    whatever comes first of the following: <br />
                    . the day the student signs an enrollment agreement.
                    <br />
                    . the day the student pays the institution an initial
                    deposit or first payment toward tuition and fees; or
                    <br />. the day that the student first visits the
                    institution if the program lasts more than 30 consecutive
                    calendar days. <br />
                    <br />
                    Thereafter, a student will be liable for: For 270-hour
                    programs (full-time) (1 Semester of 15 weeks). <br />
                    <br />
                    Refund Schedule based on Date of Enrollment.
                    <br />
                    <br />
                    The student is entitled to a refund as follows:
                    <br />
                    . 3 Days after Date of Enrollment .................... 100%
                    <br />
                    . 4 Days after Date of Enrollment .................... 50%
                    <br />
                    . 5 Days after Date of Enrollment .................... 25%
                    <br /> . After the first week of Date of Enrollment .... 0%
                    <br />
                    <br />
                  </p>
                  <div
                    className="opti-list"
                    onClick={this.props.handleCheckbox.bind(this, 5)}
                  >
                    <ul className="d-md-flex">
                      <li className="bg-white ">
                        <input
                          type="checkbox"
                          name="code_opti1"
                          value="Semantic coding"
                        />
                        I accept the Terms and Conditions.
                      </li>
                    </ul>
                  </div>

                  <br />

                  <div
                    className="row form-inner-area"
                    style={{ marginTop: "2rem" }}
                  >
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <label className="label-input">Signature</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label float-right">
                        <button
                          color="danger"
                          className="btn btn-danger "
                          onClick={this.clear.bind(this)}
                        >
                          Clear Signature
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row form-inner-area">
                    <div className="col-md-12">
                      <div className="sigContainer">
                        <SignaturePad
                          canvasProps={{
                            className: "sigPad",
                          }}
                          ref={(ref) => {
                            this.sigPad = ref;
                          }}
                          onEnd={this.trim.bind(this)}
                          clearOnResize={false}
                        />
                      </div>
                    </div>
                  </div>

                  <span
                    style={{
                      fontStyle: "italic",
                      color: "gray",
                      textAlign: "justify",
                    }}
                  >
                    By signing, I hereby certify that the above information, to
                    the best of my knowledge, is correct. I understand and agree
                    to abide by all the provisions set forth in the foregoing
                    enrollment agreement as presented in the school catalog.
                  </span>
                </div>
              </div>

              <div>
                <Modal
                  isOpen={this.props.data.ModalIsOpen}
                  onRequestClose={this.props.toggleModal.bind(this)}
                  // style={customStyles}
                  contentLabel="Example Modal"
                  overlayClassName="myoverlay"
                  className="mymodal"
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  scrollable={true}
                >
                  <h2>Please review the information below before paying:</h2>

                  <hr />

                  <div
                    className="row form-inner-area step-box"
                    style={{ paddingTop: "20px" }}
                  >
                    <div className="col-md-12">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <p style={{ whiteSpace: "pre-line" }}>{review}</p>
                      </div>
                    </div>
                  </div>

                  <hr />

                  {this.props.data.ErrorSubmit.length > 0 && (
                    <div className="row form-inner-area  ">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <div className="alert alert-danger" role="alert">
                            {this.props.data.ErrorSubmit}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row form-inner-area ">
                    <div className="col-md-12">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <button
                          color="primary"
                          className="btn btn-light "
                          style={{ margin: "0" }}
                          onClick={this.props.toggleModal.bind(this)}
                        >
                          Close
                        </button>
                        <button
                          color="danger"
                          className="btn btn-success "
                          style={{ marginLeft: "15px" }}
                          onClick={this.props.handleSubmit.bind(this)}
                          disabled={
                            this.props.data.SubmittingApplication === true
                          }
                        >
                          {total > 0 ? "Pay U$ " + total : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>

              <div>
                <Modal
                  isOpen={this.props.data.ModalPaymentIsOpen}
                  onRequestClose={this.props.toggleModalPayment.bind(this)}
                  contentLabel="Example Modal"
                  overlayClassName="myoverlay"
                  className="mymodal"
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  scrollable={true}
                >
                  <h2>Payment</h2>

                  <hr />

                  <div
                    className="row form-inner-area step-box"
                    style={{ paddingTop: "20px" }}
                  >
                    <div className="col-md-5">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <div className="row form-inner-area">
                          <div className="col-md-12">
                            <Cards
                              cvc={this.props.data.CardInformation.cvc}
                              expiry={this.props.data.CardInformation.expiry}
                              focused={this.props.data.CardInformation.focused}
                              name={this.props.data.CardInformation.name}
                              number={this.props.data.CardInformation.number}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <div className="row form-inner-area">
                          <div className="col-md-12">
                            <div className="wizard-form-input position-relative form-group has-float-label">
                              <label
                                style={{ fontSize: "15px" }}
                                className="label-input"
                              >
                                Name
                              </label>

                              <input
                                style={{ padding: "12px", height: "37px" }}
                                type="text"
                                className={`form-control `}
                                name="name"
                                placeholder="Name on card"
                                value={this.props.data.CardInformation.name}
                                onChange={this.props.handleCardInputChange.bind(
                                  this
                                )}
                                onFocus={this.props.handleInputFocus}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row form-inner-area">
                          <div className="col-md-6">
                            <div className="wizard-form-input position-relative form-group has-float-label">
                              <label
                                style={{ fontSize: "15px" }}
                                className="label-input"
                              >
                                Card Number
                              </label>

                              <input
                                style={{ padding: "12px", height: "37px" }}
                                type="text"
                                className={`form-control `}
                                name="number"
                                pattern="[\d| ]{16,22}"
                                placeholder="0000 0000 0000 0000"
                                value={this.props.data.CardInformation.number}
                                onChange={this.props.handleCardInputChange.bind(
                                  this
                                )}
                                onFocus={this.props.handleInputFocus}
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="wizard-form-input position-relative form-group has-float-label">
                              <label
                                style={{ fontSize: "15px" }}
                                className="label-input"
                              >
                                Expiry
                              </label>

                              <input
                                style={{ padding: "12px", height: "37px" }}
                                type="text"
                                className={`form-control `}
                                name="expiry"
                                placeholder="MM/YY"
                                value={this.props.data.CardInformation.expiry}
                                onChange={this.props.handleCardInputChange.bind(
                                  this
                                )}
                                onFocus={this.props.handleInputFocus}
                                pattern="\d\d/\d\d"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="wizard-form-input position-relative form-group has-float-label">
                              <label
                                style={{ fontSize: "15px" }}
                                className="label-input"
                              >
                                CVC
                              </label>

                              <input
                                style={{ padding: "12px", height: "37px" }}
                                type="text"
                                className={`form-control `}
                                name="cvc"
                                placeholder="000"
                                value={this.props.data.CardInformation.cvc}
                                onChange={this.props.handleCardInputChange.bind(
                                  this
                                )}
                                pattern="\d{3,4}"
                                onFocus={this.props.handleInputFocus}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  {this.props.data.PaymentError.length > 0 && (
                    <div className="row form-inner-area  ">
                      <div className="col-md-12">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <div className="alert alert-danger" role="alert">
                            {this.props.data.PaymentError}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="row form-inner-area "
                    style={{ float: "right" }}
                  >
                    <div className="col-md-12">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <button
                          color="primary"
                          className="btn btn-light "
                          style={{ margin: "0" }}
                          onClick={this.props.toggleModalPayment.bind(this)}
                        >
                          Close
                        </button>

                        <button
                          color="danger"
                          className="btn btn-success "
                          style={{ marginLeft: "15px" }}
                          onClick={this.props.pay.bind(this)}
                        >
                          {"Pay U$" + total}
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div className="actions">
            <ul>
              <li>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left"></i> BACK{" "}
                </span>
              </li>
              <li>
                <span
                  onClick={this.props.toggleModal.bind(this)}
                  className={` ${this.formValid() ? "" : "button-disabled"}`}
                  title="NEXT"
                >
                  REVIEW <i className="fa fa-check"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  formValid() {
    return (
      ((this.props.applicationChoiceId === 1 &&
        this.props.data.FileContent !== null &&
        this.props.data.FileName !== "" &&
        this.props.data.Agree1 === true &&
        this.props.data.Agree2 === true &&
        this.props.data.Agree3 === true &&
        this.props.data.Agree4 === true &&
        this.props.data.Agree6 === true) ||
        (this.props.applicationChoiceId === 2 &&
          this.props.data.FileContent !== null &&
          this.props.data.FileName !== "") ||
        (this.props.applicationChoiceId === 3 &&
          this.props.data.FileContent !== null &&
          this.props.data.FileName !== "" &&
          this.props.data.Agree1 === true &&
          this.props.data.Agree2 === true &&
          this.props.data.Agree3 === true &&
          this.props.data.Agree6 === true) ||
        this.props.applicationChoiceId === 4) &&
      this.props.data.Agree5 === true &&
      this.props.data.trimmedDataURL !== null &&
      this.props.data.trimmedDataURL.length > 0
    );
  }
}

export default StepSix;
